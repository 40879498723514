import "./roses.css";

const Roses = () => {
  return (
    <div className="pages">
      <div className="container">
        <div className="glass"></div>
        <div className="thorns">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="glow"></div>
        <div className="rose-leaves">
          <div></div>
          <div></div>
        </div>
        <div className="rose-petals">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="sparkles">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Roses;
