import React from "react";

import Roses from "./pages/roses";

import "./App.css";

import useClock from "./hooks/useClock";

function App() {
  useClock();
  return <Roses />;
}

export default App;
